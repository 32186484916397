
import Grid from '@mui/material/Grid2'
import React from 'react';

const GeneralLayout = ({ style, children }) => {
    return (
        <Grid container xs={12} style={{ padding: '1.5rem' }}>
            {children}
        </Grid>
    );
};

export default GeneralLayout;
