import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Balance() {
    const types = [
        { id: 1, label: 'Marcela' },
        { id: 2, label: 'Mandado' },
        { id: 3, label: 'Nómina', income: true }
    ]
    const [movementTypes, setMovementTypes] = React.useState(types.filter(type => !type.income))
    const [isIncome, setIsIncome] = React.useState(false)
    const [balance, setBalance] = React.useState([
        { date: '1/17/2025', description: 'Nómina', type: { id: 1, name: 'Nomina' }, expense: 0, income: 16471.27, balance: 16471.27 }
    ])

    React.useEffect(() => {
        setMovementTypes(types.filter(type => type.income == isIncome))
    }, [isIncome])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid size={4}>
                        <Item>
                            <Box
                                component="form"
                                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Descripción"
                                />
                                <TextField
                                    label="Monto"
                                />
                                <Autocomplete
                                    disablePortal
                                    options={movementTypes}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Tipo" />}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="Fecha" />
                                </LocalizationProvider>
                            </Box>
                        </Item>
                    </Grid>
                    <Grid size={8}>
                        <Item>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell align="right">Gasto</TableCell>
                                        <TableCell align="right">Ingreso</TableCell>
                                        <TableCell align="right">Saldo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {balance.map((row) => (
                                        <TableRow
                                            key={row.date}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.date}
                                            </TableCell>
                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>{row.type.name}</TableCell>
                                            <TableCell align="right">{row.expense}</TableCell>
                                            <TableCell align="right">{row.income}</TableCell>
                                            <TableCell align="right">{row.balance}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}
