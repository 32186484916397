import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import './App.css'

import GeneralLayout from './Layouts/GeneralLayout'

import NotFound from './Components/NotFound'
import Balance from './Components/Balance'

const App = () => {
  return (
    <Router>
      <div className="container">
        <header>
          <GeneralLayout>
            <Routes>
              <Route path='/balance' element={<Balance />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </GeneralLayout>
        </header>
      </div>

    </Router>
  )
}

export default App
